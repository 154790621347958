import React, { useEffect, useState } from 'react';

// this button should be invisible, but focus on itself every 50 ms
// it's an ugly hack
// video player, after clicked, gets the focus and holds it
// this is the only way I've found to prevent it

export const FocusHackButton = () => {
  const [buttonRef, setButtonRef] = useState<JSX.Element | null>(null);

  // make sure the container is focused for keyboard controls
  useEffect(() => {
    if (!buttonRef) return;
    const interval = setInterval(() => {
      (buttonRef as any).focus();
    }, 50);
    return () => clearInterval(interval);
  }, [buttonRef]);

  return (
    <button
      ref={ref => setButtonRef(ref as any)}
      style={{
        position: 'absolute',
        opacity: 0,
        top: '-2000px'
      }}
    >
      HACK
    </button>
  );
};
