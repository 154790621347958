import gql from 'graphql-tag';

export type Experiment = {
  id: string;
  name: string;
  type: string;
  created_at: Date;
  collaborators?: Collaborator[];
  trials_aggregate?: {
    aggregate: {
      count: number;
    }
  },
  config: ExperimentConfig;
};

export type Trial = {
  id: string;
  name: string;
  type: string; // eg. vimeo
  url: string; // link to the video
  video_delay?: number; // mouse enters the arena
  created_at: Date;
  updated_at: Date;
};

export type ExperimentConfig = {
  key: string;
  short: string;
  name: string
  measurementTimes: MeasurementTime[],
  activities: Activity[],
  stopConditions: StopCondition[]
};

export type User = {
  id: string;
  email: string;
  first_name: string;
  last_name: string;
  role: string;
};

export type Collaborator = {
  id: string;
  role: string;
  user: User;
};

export type Activity = {
  name: string;
  hotkey: string;
  default?: boolean;
  trackCount?: boolean;
};

export type ActivityWithValue = Activity & {
  isActive: boolean;
  duration: number;
  count: number;
};

export type StopCondition = ({
  combinedActivitiesLength: number,
  activities: string[]
} & {
  totalLength: number;
} & {
  totalCount: number;
  activities: string[]
});

export type MeasurementTime = {
  from: number;
  to?: number;
};

export type Result = {
  id: string;
  trial: Trial;
  created_at: Date;
  updated_at: Date;
  counts: {
    name: string;
    duration: number;
    count: number;
  }[];
  events: Event[];
};

export type Event = {
  type: string;
  activityName: string;
  at: number;
  duration: number;
};

export const ADD_EXPERIMENT = gql`
  mutation ADD_EXPERIMENT_MUTATION($objects: [experiments_insert_input!]!) {
    insert_experiments(objects: $objects) {
      affected_rows
      returning {
        id
      }
    }
  }
`;

export const ADD_COLLABORATORS = gql`
  mutation ADD_COLLABORATOR_MUTATION($objects: [experiment_collaborators_insert_input!]!) {
    insert_experiment_collaborators(objects: $objects) {
      affected_rows
    }
  }
`;

export const UPDATE_COLLABORATOR = gql`
  mutation UPDATE_COLLABORATOR($id: uuid!, $role: String!){
    update_experiment_collaborators_by_pk(_set: {role: $role}, pk_columns: {id: $id}) {
      role
    }
  }
`;

export const DELETE_COLLABORATOR = gql`
  mutation DELETE_COLLABORATOR($id: uuid!){
    delete_experiment_collaborators_by_pk(id: $id) {
      role
    }
  }
`;

export const SEARCH_COLLABORATORS = gql`
  query SEARCH_COLLABORATORS ($limit: Int!, $offset: Int!, $order_by: [users_order_by!], $where: users_bool_exp) {
    users(limit: $limit, offset: $offset, order_by: $order_by, where: $where) {
      id
      email
      first_name
      last_name
      role
    }
  }
`;

export type SearchCollaboratorsResponse = {
  users: User[]
};

export const GET_EXPERIMENTS = gql`
  query GET_EXPERIMENTS ($limit: Int!, $offset: Int!, $order_by: [experiments_order_by!], $where: experiments_bool_exp) {
    experiments_aggregate(where: $where) {
      aggregate {
        count
      }
    }

    experiments(limit: $limit, offset: $offset, order_by: $order_by, where: $where) {
      id
      name
      type
      created_at
      trials_aggregate {
        aggregate {
          count
        }
      }
    }
  }
`;

export const GET_TRIALS = gql`
  query GET_TRIALS ($limit: Int!, $offset: Int!, $order_by: [trials_order_by!], $where: trials_bool_exp) {
    trials_aggregate(where: $where) {
      aggregate {
        count
      }
    }

    trials(limit: $limit, offset: $offset, order_by: $order_by, where: $where) {
      id
      name
      type
      url
      video_delay
      created_at
      updated_at
    }
  }
`;

export type GetTrialsResponse = {
  trials_aggregate: {
    aggregate: {
      count: number
    }
  }
  trials: Trial[]
}

export const GET_TRIALS_TO_SHUFFLE = gql`
  query GET_TRIALS_TO_SHUFFLE ($experimentId: uuid!) {
    trials(where: {
      experiment_id: {
        _eq: $experimentId
      }
    }) {
      id
      name
      type
      url
      video_delay
      created_at
      updated_at
      my_results_aggregate {
        aggregate {
          count
        }
      }
    }
  }
`;

export type GetTrialsToShuffleResponse = {
  trials: (Trial & {
    my_results_aggregate: {
      aggregate: {
        count: number;
      }
    }
  })[]
}

export const GET_EXPERIMENT = gql`
  query GET_EXPERIMENT ($id: uuid!) {
    experiments_by_pk(id: $id) {
      id
      name
      type
      created_at
      collaborators {
        id
        role
        user {
          id
          email
          first_name
          last_name
          role
        }
      }
      config
    }

    me {
      id
      email
      first_name
      last_name
      role
    }
  }
`;

export type GetExperimentResponse = {
  experiments_by_pk: Experiment;
  me: User[];
};

export const IMPORT_VIMEO_TRIALS = gql`
  mutation IMPORT_VIMEO_TRIALS ($experiment_id: String!, $vimeo_album_id: String!) {
    import_vimeo_trials(input: {experiment_id: $experiment_id, vimeo_album_id: $vimeo_album_id}){
      affected_rows
    }
  }
`;

export const UPDATE_TRIAL = gql`
  mutation UPDATE_TRIAL ($id: uuid!, $set: trials_set_input) {
    update_trials_by_pk(pk_columns: { id: $id }, _set: $set) {
      id
      name
      type
      url
      video_delay
      created_at
      updated_at
    }
  }
`;

export type UpdateTrialResponse = {
  update_trials_by_pk: Trial[]
};

export const DELETE_TRIALS = gql`
  mutation DELETE_TRIALS($ids: [uuid!]!) {
    delete_trials(where: {id: {_in: $ids}}) {
      affected_rows
    }
  }
`;

export const GET_MY_PROGRESS = gql`
query GET_MY_PROGRESS($experimentId:uuid!) {
  my_results(where: {trial: {experiment_id: {_eq: $experimentId}}}) {
    id
    events
    counts
    created_at
    updated_at
  }

  trials_aggregate(where: {experiment_id: {_eq: $experimentId}}) {
    aggregate {
      count
    }
  }
}
`;

export type GetMyProgressResponse = {
  my_results: Result[];
  trials_aggregate: {
    aggregate: {
      count: number;
    };
  }
};

export const ME_QUERY = gql`
  query ME_QUERY {
    me {
      id
      email
      first_name
      last_name
      role
    }
  }
`;

export type MeResponse = {
  me: User[]
};

export const LOGOUT_QUERY = gql`
  mutation {
    doLogout @client
  }
`;

export const GET_RESULTS = gql`
  query GET_RESULTS($experimentId: uuid!) {
    results(where: {trial: {experiment_id: {_eq: $experimentId}}}, order_by: {created_at: desc}) {
      user {
        id
        email
        first_name
        last_name
        role
      }
      user_id
      created_at
      updated_at
      trial_id
      trial {
        name
      }
      events
      id
      counts
    }
  }
`;

export type SingleResultsResponse = Result & {
  user: User
  trial: {
    name: string
  }
};

export type GetResultsResponse = {
  results: SingleResultsResponse[]
};

export const GET_EXPERIMENT_AND_TRIAL = gql`
  query GET_EXPERIMENT_AND_TRIAL ($experimentId: uuid!, $trialId: uuid!) {
    experiments_by_pk(id: $experimentId) {
      id
      name
      type
      created_at
      config
    }

    trials_by_pk(id: $trialId) {
      id
      name
      type
      url
      video_delay
      created_at
      updated_at
    }
  }
`;

export type GetExperimentAndTrialResponse = {
  experiments_by_pk: Experiment;
  trials_by_pk: Trial;
};

export const INSERT_RESULT = gql`
  mutation INSERT_RESULT($trialId: uuid!, $events: jsonb, $counts: jsonb){
    insert_results_one(object:{
      trial_id: $trialId,
      events: $events,
      counts: $counts,
    }){
      id
      trial {
        id
        name
        type
        url
        created_at
        updated_at
      }
      counts
      events
    }
  }
`;

export type InsertResultResponse = {
  insert_results_one: Result;
};

