import React from 'react';
import moment from 'moment';

interface DateProps {
  relative?: boolean;
  date: string;
}

const Date = (props: DateProps) => {
  let formattedDate = '';

  if (props.relative) {
    formattedDate = moment(props.date).fromNow();
  } else {
    formattedDate = moment(props.date).format('dddd, MMMM Do YYYY, h:mm:ss a');
  }

  return <>{formattedDate}</>;
};

export default Date;