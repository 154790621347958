import React from 'react';

interface TimeInMinutesProps {
  seconds: number;
}

export const TimeInMinutes = (props: TimeInMinutesProps) => {
  const { seconds: totalSeconds } = props;
  const minutes = Math.floor(totalSeconds / 60);
  const seconds = totalSeconds % 60;

  return <>{`${minutes}:${seconds < 10 ? '0' + seconds : seconds}`}</>;
};

export default TimeInMinutes;