import React from 'react';
import { Typography } from 'antd';
import { SpinnerPage } from '../../../components/SpinnerPage';
import { Experiment } from '../../../graphql/queries';

// import style from './StopConditions.module.css';

const { Text } = Typography;

interface StopConditionsProps {
  experiment: Experiment;
}

export const StopConditions = ({ experiment }: StopConditionsProps) => {
  if (!experiment?.config) {
    return <SpinnerPage />;
  }

  const { stopConditions } = experiment.config;

  return (
    <>
    {stopConditions.map(stopCondition => {
      if (stopCondition.totalLength) {
        return <><Text code>{stopCondition.totalLength}</Text> seconds of evaluation has passed</>
      } else if (stopCondition.combinedActivitiesLength && stopCondition.activities) {
        return (
          <>
            Combined <Text underline>length</Text> of activities {stopCondition.activities.map((a, i) => <>{i > 0 ? ' and ' : ''} <Text code>{a}</Text></>)}
            {' '}is equal <Text code>{stopCondition.combinedActivitiesLength} seconds</Text>
          </>
        );
      } else if (stopCondition.totalCount && stopCondition.activities) {
        return (
          <>
            {stopCondition.activities.map((a, i) => <>{i > 0 ? ' and ' : ''} <Text code>{a}</Text></>)}
            activity occurred <Text code>{stopCondition.totalCount}</Text> times
          </>
        );
      }
      return <Text type="danger">Unknown condition</Text>;
    }).map((condition, i) => <>{i > 0 ? <><br /><i style={{ paddingLeft: '4px' }}>or</i><br /></> : <></>}{condition}</>)}
    </>
  );
};
