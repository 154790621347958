import gql from 'graphql-tag';

export const LOGIN = gql`
  mutation LOGIN($email:String!, $password:String!) {
    login(input: {password: $password, email: $email}) {
      token
    }
  }
`;

export const SAVE_AUTH_TOKEN = gql`
  mutation SAVE_AUTH_TOKEN($token: String!) {
    saveAuthToken(token: $token) @client
  }
`;

export const REGISTER = gql`
  mutation REGISTER($email:String!, $password:String!, $firstName: String!, $lastName: String!) {
    register(input: {password: $password, email: $email, first_name: $firstName, last_name: $lastName}) {
      token
    }
  }
`;

export const ME = gql`
  query ME{
    me {
      id
      email
      first_name
      last_name
      updated_at
      created_at
    }
  }
`;