import React from 'react';
import { Card, Button, Progress, Tooltip } from 'antd';
import { useHistory } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';
import { GET_MY_PROGRESS, GetMyProgressResponse, Experiment } from '../../../graphql/queries';
import { ErrorPage, SpinnerPage } from '../../../components';
import style from './EvaluationStatus.module.css';

interface EvaluationStatusProps {
  experiment: Experiment;
}

export const EvaluationStatus = (props: EvaluationStatusProps) => {
  const { experiment } = props;
  const history = useHistory();
  const { loading, error, data } = useQuery<GetMyProgressResponse>(GET_MY_PROGRESS, {
    variables: { experimentId: experiment.id }
  });

  if (error) {
    console.error(error);
    return <ErrorPage />;
  }

  if (loading || !data) return <SpinnerPage />;

  const progress = {
    evaluated: data.my_results.length,
    total: data.trials_aggregate.aggregate.count
  };

  const progressPercentage = Math.round((progress.evaluated / progress.total) * 100)

  return (
    <Card title="Your evaluation progress" className={style.card}>
      <div className={style.cardContent}>
        <div className={style.percent}>
          <Progress type="circle" percent={progressPercentage} />
        </div>
        <div className={style.description}>
          <p className={style.textWrapper}>You evaluated {progress.evaluated} of {progress.total} trials.</p>
          <p className={style.buttonWrapper}>
            <Tooltip title={tooltipMessage(progressPercentage, progress.total)}>
              <Button
                type="primary"
                onClick={() => history.push(`/evaluate/${experiment.id}`)}
                disabled={progressPercentage === 100 || progress.total === 0}
              >
                Evaluate next recording
              </Button>
            </Tooltip>
          </p>
        </div>
      </div>
    </Card>
  );
};

function tooltipMessage(progressPercentage: number, progressTotal: number): string {
  if (progressTotal === 0) {
    return 'There is no recordings to evaluate. Please add recordings or contact the experiment owner';
  } else if (progressPercentage === 100) {
    return 'You evaluated all recordings, have some tea and enjoy the rest of the day!';
  }
  return 'Evaluate next random recording';
}
