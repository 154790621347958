// FIXME: move to hasura

const defaultTypes = [{
  key: 'fst',
  short: 'FST',
  name: 'Forced Swim Test',
  measurementTimes: [{ from: 120, to: 360 }],
  activities: [
    { name: 'Immobile', hotkey: 'left', default: true },
    { name: 'Climbing', hotkey: 'up' },
    { name: 'Swimming', hotkey: 'right' }
  ],
  // stop after 240s of a measurement time
  stopConditions: [{
    totalLength: 240
  }]
}, {
  key: 'nor',
  short: 'NOR',
  name: 'Novel Object Recognition Test',
  measurementTimes: [{ from: 0 }],
  activities: [
    { name: 'Left',  hotkey: 'left' },
    { name: 'None',  hotkey: 'up', default: true },
    { name: 'Right', hotkey: 'right' }
  ],
  stopConditions: [{
    combinedActivitiesLength: 20,
    activities: ['Left', 'Right']
  }]
}, {
  key: 'epm',
  short: 'EPM',
  name: 'Elevated Plus Maze',
  measurementTimes: [{ from: 0, to: 300 }],
  activities: [
    { name: 'Closed', hotkey: 'left', default: true},
    { name: 'Middle', hotkey: 'up'},
    { name: 'Open',   hotkey: 'right'}
  ],
  stopConditions: [{
    totalLength: 300
  }]
}, {
  key: 'tst',
  short: 'TST',
  name: 'Tail Suspension Test',
  measurementTimes: [{ from: 0, to: 360 }],
  activities: [
    { name: 'Immobile', hotkey: 'left', default: true },
    { name: 'Climbing', hotkey: 'up' }
  ],
  stopConditions: [{
    totalLength: 360
  }]
}, {
  key: 'scratch',
  short: 'SCRATCH',
  name: 'Scratch Test',
  measurementTimes: [{ from: 0, to: 120 }, { from: 200, to: 360 }],
  activities: [
    { name: 'Normal',   hotkey: 'left', default: true},
    { name: 'Scratching', hotkey: 'up'}
  ],
  stopConditions: [{
    totalLength: 280
  }]
},{
  key: 'splash',
  short: 'SPLASH',
  name: 'Splash Test',
  measurementTimes: [{ from: 0, to: 300 }],
  activities: [
    { name: 'Normal',   hotkey: 'left', default: true},
    { name: 'Grooming', hotkey: 'up'}
  ],
  stopConditions: [{
    totalLength: 300
  }]
}, {
  key: 'barnes',
  short: 'BARNES',
  name: 'Barnes Test',
  measurementTimes: [{ from: 0 }],
  activities: [
    { name: 'Default',     hotkey: 'd', default: true },
    { name: 'Head Escape', hotkey: 'left',   trackCount: true },
    { name: 'Body Escape', hotkey: 'up', trackCount: true },
    { name: 'Head Error',  hotkey: 'right',  trackCount: true },
    { name: 'Body Error',  hotkey: 'down',   trackCount: true }
  ],
  stopConditions: [{
    totalCount: 1,
    activities: ['Body Escape']
  }, {
    totalLength: 180
  }]
}];

export function getType(key: string) {
  const type = defaultTypes.find(type => type.key === key);
  return type || {
    key,
    short: key.toUpperCase(),
    name: `Unknown Type (${key})`
  };
}

export default defaultTypes;
