import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { HttpLink } from 'apollo-link-http';
import { setContext } from 'apollo-link-context';

const ANONYMOUS_TOKEN = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIwMDAwMDAwMDAwIiwiaWF0IjoxNTg0NzI1NTU1LCJyb2xlIjoiYW5vbnltb3VzIiwiaHR0cHM6Ly9oYXN1cmEuaW8vand0L2NsYWltcyI6eyJ4LWhhc3VyYS1hbGxvd2VkLXJvbGVzIjpbImFub255bW91cyJdLCJ4LWhhc3VyYS1kZWZhdWx0LXJvbGUiOiJhbm9ueW1vdXMifX0.6ZExQ1PTpIiHD6dgevsUXpkuvZi3GqQqLS5jV2PkpHg';

const cache = new InMemoryCache();

const httpLink = new HttpLink({
  uri: '/v1/graphql'
});

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem('token') || ANONYMOUS_TOKEN;
  return {
    headers: {
      ...headers,
      authorization: `Bearer ${token}`
    }
  }
});

export const client = new ApolloClient({
  cache,
  link: authLink.concat(httpLink),
  resolvers: {
    Query: {
      isAuthenticated: () => {
        return !!localStorage.getItem('token');
      }
    },
    Mutation: {
      saveAuthToken: (_root, { token }, { client }) => {
        localStorage.setItem('token', token);
        client.resetStore();
      },
      doLogout: (_root, _, { client }) => {
        localStorage.removeItem('token');
        client.resetStore();
      }
    },
  }
});
