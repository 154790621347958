/*eslint no-restricted-globals: ["error", "event"]*/
import React, { useEffect } from 'react';
import { Button, notification } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import { GET_RESULTS, GetResultsResponse, SingleResultsResponse /*, Event */ } from '../../../graphql/queries';
import { useLazyQuery } from '@apollo/react-hooks';

export function ExportToCSVButton(props: { experimentId: string }) {
  const { experimentId } = props;
  const [getResults, { loading, data }] = useLazyQuery<GetResultsResponse>(GET_RESULTS, {
    variables: { experimentId }
  });

  console.log('data', data);

  useEffect(() => {
    if (data?.results && data?.results.length > 0) {
      const csvData: any = resultsToCSV(data?.results);
      downloadCSV(csvData, `results-${new Date().getTime()}.csv`);
    }
  }, [data]);

  async function generateCSV() {
    try {
      await getResults();
    } catch (err) {
      console.error(err);
      notification.error({
        message: 'Failed to generate CSV',
        description: 'Please try again later'
      });
    }
  }

  return (
    <Button
      type="default"
      icon={<DownloadOutlined />}
      loading={loading}
      disabled={loading}
      onClick={() => generateCSV()}
    >
      Download results [CSV]
    </Button>
  );
}

function resultsToCSV(results: GetResultsResponse['results']) {
  const header = [
    'Time',
    'Trial',
    'Evaluated by',
    ...(results[0].counts.map(count => `${count.name} [s]`)),
    ...(results[0].counts.map(count => `${count.name} [count]`)),
    ...(results[0].counts.map(count => `Time to first "${count.name}" [s]`)),
    ];

  const rows = results.map(result => {
    const row = [
      result.created_at,
      result.trial.name,
      `${result.user.first_name} ${result.user.last_name} <${result.user.email}>`,
      ...(result.counts.map(count => Math.round(count.duration * 100) / 100)),
      ...(result.counts.map(count => count.count)),
      ...(result.counts.map(count => getTimeToFirstEvent(count.name, result)))
      // ...(result.counts.map(count => calculateCountsFromEvents(count.name, result.events)))
    ];

    return row;
  });

  return [header, ...rows];
}

function getTimeToFirstEvent(activityName: string, result: SingleResultsResponse) {
  if (result.events.length < 2) return 'n/a';

  // Example events array:
  // {at: 0, type: 'init'}
  // {at: 127, type: 'activity', activityName: 'Immobile'}
  // {at: 136.005, type: 'activity', activityName: 'Climbing'}
  // {at: 136.505, type: 'activity', activityName: 'Immobile'}
  // {at: 137.507, type: 'activity', activityName: 'Swimming'}
  // {at: 138.257, type: 'activity', activityName: 'Immobile'}
  // {at: 164.815, type: 'activity', activityName: 'Swimming'}
  // {at: 165.814, type: 'activity', activityName: 'Immobile'}

  // start with i=1 to ignore the init selection
  let startTime = 0;
  for (let i = 1; i < result.events.length; i++) {
    const event = result.events[i];
    if (i === 1) {
      // ignore the first event, it's the default. Only save start time
      startTime = event.at;
    } else if (event.activityName === activityName) {
      return event.at - startTime;
    }
  }

  // activity not found
  return 'n/a';
}

/**
 * use this function if you need to recount the events
 */
// function calculateCountsFromEvents(activityName: string, events: Event[]) {
//   let count = 0;
//   events.forEach((event, i) => {
//     if (i === 0) return;
//     const previousEvent = events[i - 1];
//     if (event.activityName !== previousEvent.activityName && activityName === event.activityName) {
//       count++;
//     }
//   });
//   return count;
// }

function downloadCSV(data: (string|number)[][], filename: string) {
  // Building the CSV from the Data two-dimensional array
  // Each column is separated by ";" and new line "\n" for next row
  let csvContent = '';
  data.forEach(function(infoArray: any, index: number) {
    const dataString = infoArray.join(';');
    csvContent += index < data.length ? dataString + '\n' : dataString;
  });

  // The download function takes a CSV string, the filename and mimeType as parameters
  // Scroll/look down at the bottom of this snippet to see how download is called
  const download = (content: string, fileName: string, mimeType: string) => {
    let a = document.createElement('a');
    mimeType = mimeType || 'application/octet-stream';

    if (navigator.msSaveBlob) { // IE10
      navigator.msSaveBlob(new Blob([content], {
        type: mimeType
      }), fileName);
    } else if (URL && 'download' in a) { //html5 A[download]
      a.href = URL.createObjectURL(new Blob([content], {
        type: mimeType
      }));
      a.setAttribute('download', fileName);
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    } else {
      (location as any).href = 'data:application/octet-stream,' + encodeURIComponent(content); // only this mime type is supported
    }
  }

  download(csvContent, filename, 'text/csv;encoding:utf-8');
}
