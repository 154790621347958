import React from 'react';
import { Button, Form, Input, Alert } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/react-hooks';
import { extractErrors }from './utils';
import { LOGIN, SAVE_AUTH_TOKEN } from './queries';
import * as _ from 'lodash';
import style from './Login.module.css';

export const LoginForm = () => {
  const [doLogin, result] = useMutation(LOGIN);
  const [saveAuthToken] = useMutation(SAVE_AUTH_TOKEN);
  const errors = extractErrors(result);

  const onSubmit = async(values: any) => {
    try {
      const result = await doLogin({ variables: values });
      await saveAuthToken({
        variables: {
          token: _.get(result, 'data.login.token', '')
        }
      });
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Form
      name="login"
      onFinish={onSubmit}
    >
      {errors.length ? (
        <Form.Item name="errors">
          {errors.map(error => (<Alert message={error} type="error" />))}
        </Form.Item>
      ) : undefined}

      <Form.Item
        name="email"
        rules={[{ required: true, message: 'Please input your e-mail!' }]}
      >
        <Input
          prefix={<UserOutlined />}
          placeholder="E-mail"
          type="email"
          autoComplete="off"
          disabled={result.loading}
        />
      </Form.Item>
      <Form.Item
        name="password"
        rules={[{ required: true, message: 'Please input your password!' }]}
      >
        <Input
          prefix={<LockOutlined />}
          type="password"
          placeholder="Password"
          disabled={result.loading}
        />
      </Form.Item>
      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          className={style.submit}
          loading={result.loading}
        >
          Log in
        </Button>
      </Form.Item>
    </Form>
  );
}