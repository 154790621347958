import React, { useState } from 'react';
import Search, { SearchProps } from 'antd/lib/input/Search';

const DEBOUNCE_TIME = 700;

const DebouncedSearch = (props: SearchProps) => {
  const { onSearch } = props;

  const [timeout, setStateTimeout] = useState<null | NodeJS.Timeout>(null);

  const debounceOnChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = ev.target;
    if (timeout) {
      clearTimeout(timeout);
    }
    setStateTimeout(
      setTimeout(() => {
        onSearch && onSearch(value);
      }, DEBOUNCE_TIME)
    );
  };

  return (
    <Search
      {...props}
      onChange={(ev: React.ChangeEvent<HTMLInputElement>) => {
        props.onChange && props.onChange(ev);
        debounceOnChange(ev);
      }}
    />
  );
};

export default DebouncedSearch;
