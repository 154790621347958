import React, { useEffect } from 'react';
import { Form, Input, Button, Alert, Modal, Row, Col, message } from 'antd';
import { FormProps } from 'antd/lib/form';
import { useMutation } from '@apollo/react-hooks';
import ReactPlayer from 'react-player';
import { UPDATE_TRIAL, Trial } from '../../../graphql/queries';
import style from './TrialModal.module.css';

interface TrialModalProps extends FormProps {
  visible: boolean;
  onCloseModal: () => void;
  trial: Trial;
}

export const TrialModal = (props: TrialModalProps) => {
  const { visible, onCloseModal, trial } = props;
  const [form] = Form.useForm();
  const [updateTrial, { loading, error }] = useMutation(UPDATE_TRIAL);

  useEffect(() => form.resetFields(), [trial, form])

  const onSubmit = async(values: any) => {
    try {
      await updateTrial({
        variables: {
          id: trial.id,
          set: { ...values }
        }
      });
      props.onCloseModal();

    } catch (err){
      message.error('An unknown error occurred, please try again later');
      console.error(err);
    }
  };

  return (
    <Modal
      title="Update trial"
      visible={visible}
      onCancel={onCloseModal}
      width={900}
      footer={[
        <Button key="back" onClick={onCloseModal}>
          Cancel
        </Button>,
        <Button key="submit" type="primary" loading={loading} disabled={loading} onClick={() => form.submit()}>
          Save
        </Button>
      ]}
    >
      <Row>
        <Col span={16}>
          <ReactPlayer
            url={trial.url}
            controls={true}
            width={540}
          />
        </Col>
        <Col span={8}>
          <Form
            form={form}
            layout="vertical"
            onFinish={onSubmit as any}
            initialValues={trial}
          >
            {error ? (
              <Alert
                className={style.errorMessage}
                message={error.message}
                type="error"
              />
            ) : null}

            <Form.Item
              name="name"
              label="Name"
              rules={[{
                required: true,
                message: 'Please input the name'
              }]}
            >
              <Input autoFocus disabled={loading} autoComplete="off" />
            </Form.Item>

            <Form.Item
              name="video_delay"
              label="Video delay [s]"
              rules={[{
                pattern: /[\d]+/,
                message: 'Delay must be a number'
              }]}
              extra="A moment when mouse enters the arena"
              >
              <Input disabled={loading} autoComplete="off" type="number" />
            </Form.Item>

            {/* without this button ENTER is not submitting form */}
            <Button htmlType="submit" style={{display: 'none'}}>Submit</Button>
          </Form>
        </Col>
      </Row>
    </Modal>
  );
};

