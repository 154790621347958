import React, { useState } from 'react';
import { Card, Badge, Table, Button, Tooltip } from 'antd';
import { useQuery } from '@apollo/react-hooks';
import { PlusOutlined } from '@ant-design/icons';
import { GET_TRIALS, GetTrialsResponse, Trial, Experiment, User } from '../../graphql/queries';

import { DeleteTrialModal } from './modals/DeleteTrialModal';
import { ImportVideosModal } from './modals/ImportVideosModal';
import { canImport } from './permissions';
import { ErrorPage } from '../../components';

const PAGE_SIZE = 20;

const badgeStyle = {
  backgroundColor: '#fff',
  color: '#999',
  boxShadow: '0 0 0 1px #d9d9d9 inset'
};

const tableColumns = [
  {
    title: 'Name',
    key: 'name',
    render: (text: any, trial: Trial) => `${trial.name}`
  },
  {
    title: 'Delay',
    key: 'delay',
    render: (text: any, trial: Trial) => `${trial.video_delay}`
  }
];

interface TrialsListProps {
  experiment: Experiment;
  onTrialClick: (trial: Trial) => void;
  me: User;
}

export const TrialsList = (props: TrialsListProps) => {
  const { experiment, me } = props;

  const { loading, error, data, fetchMore } = useQuery<GetTrialsResponse>(GET_TRIALS, {
    variables: {
      limit: PAGE_SIZE,
      offset: 0,
      order_by: {
        name: 'asc'
      },
      where: {
        experiment_id: {
          _eq: experiment.id
        }
      }
    }
  });

  const [currentPage, setCurrentPage] = useState(1);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [importModalVisible, setImportModalVisible] = useState(false);
  const trials = data?.trials || [];
  const totalCount = data?.trials_aggregate?.aggregate?.count || 0;

  if (error) {
    console.error(error);
    return <ErrorPage />;
  }

  const handlePaginationChange = (page: number, pageSize?: number) => {
    const limit = pageSize || PAGE_SIZE;
    setCurrentPage(page);
    fetchMore({
      variables: {
        offset: (page - 1) * limit
      },
      updateQuery: (_, { fetchMoreResult }) => fetchMoreResult as any
    })
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: (keys: any) => setSelectedRowKeys(keys)
  };

  const hasSelected = selectedRowKeys.length > 0;

  return (
    <Card title={[
        'Trials ',
        <Badge count={totalCount} style={badgeStyle} overflowCount={1000} />,
        canImport(experiment, me) && (
          <Tooltip title="Import videos as trials">
            <Button
              type={totalCount > 0 ? 'default' : 'primary'}
              icon={<PlusOutlined />}
              onClick={() => setImportModalVisible(true)}
              style={{ float: 'right' }}
            >Add videos</Button>
          </Tooltip>
        )
      ]}>
      <Table
        size="middle"
        columns={tableColumns}
        rowSelection={rowSelection}
        onRow={(trial: Trial) => ({
          onClick: (ev) => {
            if ((ev.target as any).type !== 'checkbox') {
              props.onTrialClick(trial)
            }
          }
        })}
        pagination={{
          pageSize: PAGE_SIZE,
          onChange: handlePaginationChange,
          current: currentPage,
          total: totalCount,
          hideOnSinglePage: true
        }}
        loading={loading}
        dataSource={trials.map((trial: Trial) => ({ key: trial.id, ...trial }))}
        footer={() => (
          <div style={{ marginBottom: 16 }}>
            <Button type="danger" onClick={() => setDeleteModalVisible(true)} disabled={!hasSelected}>
              Delete
            </Button>
            <span style={{ marginLeft: 8 }}>
              {hasSelected ? `Selected ${selectedRowKeys.length} items` : ''}
            </span>
            <DeleteTrialModal
              visible={deleteModalVisible}
              onCloseModal={() => setDeleteModalVisible(false)}
              selectedRowKeys={selectedRowKeys}
              setSelectedRowKeys={setSelectedRowKeys as any}
            />
          </div>
        )}
      />
      <ImportVideosModal
        visible={importModalVisible}
        experiment={experiment}
        onCloseModal={() => setImportModalVisible(false)}
      />
    </Card>
  );
};
