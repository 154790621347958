import React from 'react';
import { Layout } from 'antd';

const { Content } = Layout;

export const UsersPage = () => (
  <Layout className="layout">
    <Content style={{ padding: '0 50px' }}>
      <div style={{
        background: '#fff',
        padding: 24,
        minHeight: 280,
        marginTop: '50px',
        border: '1px solid #ccc'
      }}>Users Page</div>
    </Content>
  </Layout>
);
