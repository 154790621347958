import React from 'react';
import { Button, Form, Input, Alert } from 'antd';
import { UserOutlined, LockOutlined, MailOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/react-hooks';
import { REGISTER, SAVE_AUTH_TOKEN } from './queries';
import { extractErrors } from './utils';
import * as _ from 'lodash';
import style from './RegisterForm.module.css';

export const RegisterForm = () => {
  const [doRegister, result] = useMutation(REGISTER);
  const [saveAuthToken] = useMutation(SAVE_AUTH_TOKEN);
  const errors = extractErrors(result);

  const onSubmit = async(values: any) => {
    try {
      const result = await doRegister({ variables: values });
      await saveAuthToken({
        variables: {
          token: _.get(result, 'data.register.token', '')
        }
      });
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Form
      name="register"
      onFinish={onSubmit}
    >
      {errors.length ? (
        <Form.Item name="errors">
          {errors.map(error => (<Alert message={error} type="error" />))}
        </Form.Item>
      ) : undefined}
      <Form.Item
        name="firstName"
        rules={[{ required: true, message: 'Please input your first name!' }]}
        hasFeedback
      >
        <Input
          prefix={<UserOutlined />}
          placeholder="First name"
          autoComplete="off"
          disabled={result.loading}
        />
      </Form.Item>
      <Form.Item
        name="lastName"
        rules={[{ required: true, message: 'Please input your last name!' }]}
        hasFeedback
      >
        <Input
          prefix={<UserOutlined />}
          placeholder="Last name"
          autoComplete="off"
          disabled={result.loading}
        />
      </Form.Item>
      <Form.Item
        name="email"
        rules={[{ required: true, message: 'Please input your e-mail!' }]}
        hasFeedback
      >
        <Input
          prefix={<MailOutlined />}
          placeholder="E-mail"
          type="email"
          autoComplete="off"
          disabled={result.loading}
        />
      </Form.Item>
      <Form.Item
        name="password"
        rules={[{ required: true, message: 'Please input your password!' }]}
        hasFeedback
      >
        <Input.Password
          prefix={<LockOutlined />}
          placeholder="Password"
          disabled={result.loading}
        />
      </Form.Item>
      <Form.Item
        name="confirm"
        dependencies={['password']}
        hasFeedback
        rules={[
          {
            required: true,
            message: 'Please confirm your password!',
          },
          ({ getFieldValue }) => ({
            validator(rule, value) {
              if (!value || getFieldValue('password') === value) {
                return Promise.resolve();
              }
              return Promise.reject('The two passwords that you entered do not match!');
            },
          }),
        ]}
      >
        <Input.Password
          prefix={<LockOutlined />}
          placeholder="Confirm Password"
          disabled={result.loading}
        />
      </Form.Item>

      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          className={style.submit}
          loading={result.loading}
        >
          Register
        </Button>
      </Form.Item>
    </Form>
  );
}