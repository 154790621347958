import React from 'react';
import { Timeline } from 'antd';
import { TimeInMinutes } from '../../../components/Time';
import { SpinnerPage } from '../../../components/SpinnerPage';
import { Experiment } from '../../../graphql/queries';

import style from './MeasurementTimes.module.css';

interface MeasurementTimesProps {
  experiment: Experiment;
}

export const MeasurementTimes = ({ experiment }: MeasurementTimesProps) => {
  if (!experiment?.config) {
    return <SpinnerPage />;
  }

  const { measurementTimes: times } = experiment.config;

  return (
    <Timeline className={style.timeline}>
      <Timeline.Item>
        Experiment begins
      </Timeline.Item>
      {(times || []).map(time => (
        <>
          <Timeline.Item color="green" key={`from-${time.from}`}>
            <TimeInMinutes seconds={time.from} /> -
            Measurement starts
          </Timeline.Item>
          {time.to && (
            <Timeline.Item color="green" key={`to-${time.to}`}>
              <TimeInMinutes seconds={time.to} /> -
              Measurement stops
            </Timeline.Item>
          )}
        </>
      ))}
      <Timeline.Item>
        Experiment ends
      </Timeline.Item>
    </Timeline>
  );
};
