import React from 'react';
import cx from 'classnames';

import { ActivityWithValue } from '../../graphql/queries';
import { formatTime } from './utils';
import { KeyboardKey } from './KeyboardKey';

import style from './Sidebar.module.css';

type SidebarProps = {
  activitiesWithCount: ActivityWithValue[];
  selectActivity: (activity: ActivityWithValue) => void
};

export const Sidebar = (props: SidebarProps) => {
  const { activitiesWithCount, selectActivity } = props;

  const activities = activitiesWithCount.map(activity => {
    return (
      <div
        key={activity.name}
        className={cx(style.activity, activity.isActive ? style.activityActive : '')}
        onClick={() => selectActivity(activity)}
      >
        <KeyboardKey keyboardKey={activity.hotkey} />
        <span className={style.timer}>{formatTime(activity.duration)}</span>
        <span className={style.activityName}>{activity.name}</span>
        <span className={style.count}>{activity.count}</span>
      </div>
    );
  });

  return <>{activities}</>;
};
