import React, { useState } from 'react';
import { Form, Input, Button, Alert, Modal, Select } from 'antd';
import { FormProps } from 'antd/lib/form';
import { useQuery, useMutation } from '@apollo/react-hooks';
import experimentTypes, { getType as getExperimentType } from '../experimentTypes';
import style from './AddExperimentModal.module.css';
import { ADD_EXPERIMENT, ME_QUERY } from '../../../graphql/queries';

const DEFAULT_EXPERIMENT_TYPE = 'fst';

const { Option } = Select;

interface AddExperimentModalProps extends FormProps {
  visible: boolean;
  onCloseModal: () => void;
}

export const AddExperimentModal = (props: AddExperimentModalProps) => {
  const [form] = Form.useForm();
  const { data } = useQuery(ME_QUERY);
  const [addExperiment, { loading: saving, error }] = useMutation(ADD_EXPERIMENT, {
    refetchQueries: ['GET_EXPERIMENTS']
  });
  const { visible, onCloseModal } = props;
  const [selectedType, setSelectedType] = useState(DEFAULT_EXPERIMENT_TYPE);
  const experimentType = getExperimentType(selectedType);
  const user = data?.me?.[0] || {};

  const onSubmit = async(values: any) => {
    try {
      await addExperiment({
        variables: {
          objects: {
            name: values.name,
            type: values.type,
            config: experimentType,
            collaborators: {
              data: {
                user_id: user.id,
                role: 'owner'
              }
            }
          }
        }
      });

      props.onCloseModal();
      form.resetFields();
    } catch (err){
      console.error(err);
    }
  };

  return (
    <Modal
      title="Add Experiment"
      visible={visible}
      onCancel={onCloseModal}
      footer={[
        <Button key="back" onClick={onCloseModal}>
          Cancel
        </Button>,
        <Button key="submit" type="primary" loading={saving} onClick={() => form.submit()}>
          Add
        </Button>
      ]}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={onSubmit as any}
        onSubmitCapture={(ev) => {
          console.log('submit capture', ev);
        }}
        initialValues={{
          type: DEFAULT_EXPERIMENT_TYPE
        }}
      >
        {error ? (
          <Alert
            className={style.errorMessage}
            message={error.message}
            type="error"
          />
        ) : null}

        <Form.Item
          name="name"
          label="Name"
          rules={[{
            required: true,
            message: 'Please input the name'
          }]}
        >
          <Input autoFocus disabled={saving} autoComplete="off" />
        </Form.Item>

        <Form.Item
          name="type"
          label="Type"
          rules={[{
            required: true,
            message: 'Please select the type'
          }]}
          >
          <Select onChange={(type: string) => setSelectedType(type)}>
            {experimentTypes.map(type => (
              <Option value={type.key}>{type.name}</Option>
            ))}
          </Select>
        </Form.Item>

        {/* {selectedType && (
          <Form.Item label="Measurement time configuration">
            <MeasurementTimes times={measurementTimes} />
          </Form.Item>
        )*/}

        {/* without this button ENTER is not submitting form*/}
        <Button htmlType="submit" style={{display: 'none'}}>Submit</Button>
      </Form>
    </Modal>
  );
};

