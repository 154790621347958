import React from 'react';
import { Card, Tabs } from 'antd';
import { LoginForm } from './LoginForm';
import { RegisterForm } from './RegisterForm';
import style from './AuthPage.module.css';
import icon from '../logo.png';

const { TabPane } = Tabs;

export const AuthPage = () => (
  <div className={style.login}>
    <Card className={style.card} bordered={false} style={{background: 'transparent'}}>
      <img src={icon} alt="aLab" className={style.icon} />

      <Tabs defaultActiveKey="1" size="large" tabBarStyle={{textAlign: 'center'}} animated={false}>
        <TabPane tab="Login" key="1">
          <LoginForm />
        </TabPane>
        <TabPane tab="Register" key="2">
          <RegisterForm />
        </TabPane>
      </Tabs>
    </Card>
  </div>
);