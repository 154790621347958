import React, { useEffect, useState } from 'react';
import { Form, Input, Button, Modal, AutoComplete, Radio, Typography, notification } from 'antd';
import { LoadingOutlined, SearchOutlined } from '@ant-design/icons';
import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import { Experiment, SEARCH_COLLABORATORS, SearchCollaboratorsResponse, User, ADD_COLLABORATORS } from '../../../graphql/queries';

const PAGE_SIZE = 20;
const DEBOUNCE_TIME = 500;
const radioStyle = {
  display: 'block'
};

interface InviteCollaboratorModalProps {
  visible: boolean;
  experiment: Experiment;
  onCloseModal: () => void;
}

export const InviteCollaboratorModal = (props: InviteCollaboratorModalProps) => {
  const { visible, onCloseModal, experiment } = props;
  const [form] = Form.useForm();
  const [searchCollaborators, { loading, data }] = useLazyQuery<SearchCollaboratorsResponse>(SEARCH_COLLABORATORS);
  const [addCollaborator, { loading: savingCollaborator, error: collaboratorError }] = useMutation(ADD_COLLABORATORS, {
    refetchQueries: ['GET_EXPERIMENTS', 'GET_EXPERIMENT']
  });
  const [timeout, setStateTimeout] = useState<null | NodeJS.Timeout>(null);

  useEffect(() => {
    collaboratorError?.graphQLErrors.forEach(error => {
      if (error.extensions?.code === 'constraint-violation') {
        notification.error({
          message: 'Collaborator alread added'
        });
      } else {
        notification.error({
          message: 'Failed to add collaborator',
          description: 'Please try again later'
        });
      }
    });
  }, [collaboratorError]);

  const options = data?.users.map((user: User) => ({
    text: `<${user.email}>`,
    value: user.email
  })) || [];

  return (
    <Modal
      title="Add collaborator"
      visible={visible}
      onCancel={onCloseModal}
      footer={[
        <Button key="back" onClick={onCloseModal}>
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={() => form.submit()}
          disabled={savingCollaborator}
          loading={savingCollaborator}
        >
          Add
        </Button>
      ]}
    >
      <Form
        layout="vertical"
        onFinish={onFinish as any}
        form={form}
      >
        <Form.Item name="email" label="E-mail" rules={[{
          required: true,
          message: 'Please input the e-mail'
        }, () => ({
          validator(rule, email) {
            const userId = getUserId(email);
            if (userId) {
              return Promise.resolve();
            }
            return Promise.reject('User with this e-mail has not been found');
          },
        })]}>
          <AutoComplete
            options={options}
            onChange={debounceOnChange as any}
          >
            <Input suffix={loading ? <LoadingOutlined /> : <SearchOutlined />} />
          </AutoComplete>
        </Form.Item>

        <Form.Item label="Role" name="role" rules={[{ required: true }]}>
          <Radio.Group defaultValue="user">
            <Radio value="owner" style={radioStyle}>
              <Typography.Text strong>Owner</Typography.Text>
              <Typography.Paragraph style={{ paddingLeft: '24px' }}>Has full administrative access to this experiment.</Typography.Paragraph>
            </Radio>
            <Radio value="user" style={radioStyle}>
              <Typography.Text strong>User</Typography.Text>
              <Typography.Paragraph style={{ paddingLeft: '24px' }}>Can't edit the experiment or add videos, but can see the results</Typography.Paragraph>
            </Radio>
            <Radio value="evaluator" style={radioStyle}>
              <Typography.Text strong>Evaluator</Typography.Text>
              <Typography.Paragraph style={{ paddingLeft: '24px' }}>Can only evaluate trials, can't see the results</Typography.Paragraph>
            </Radio>
          </Radio.Group>
        </Form.Item>
      </Form>
    </Modal>
  );

  function triggerSearchCollaborators(search: string) {
    return searchCollaborators({
      variables: {
        limit: PAGE_SIZE,
        offset: 0,
        order_by: {
          email: 'desc'
        },
        where: search ? {
          email: {
            _ilike: `%${search}%`
          }
        } : {}
      }
    });
  }

  function debounceOnChange(value: string) {
    if (timeout) {
      clearTimeout(timeout);
      setStateTimeout(null);
    }
    setStateTimeout(
      setTimeout(() => {
        triggerSearchCollaborators(value);
      }, DEBOUNCE_TIME)
    );
  };

  function getUserId(email: string) {
    if (data) {
      for (const user of data.users) {
        if (user.email === email) {
          return user.id;
        }
      }
    }
  }

  async function onFinish(values: {
    email: string;
    role: string;
  }) {
    try {
      await addCollaborator({
        variables: {
          objects: {
            user_id: getUserId(values.email),
            experiment_id: experiment.id,
            role: values.role
          }
        }
      });

      props.onCloseModal();
      form.resetFields();
      notification.success({
        message: 'Collaborator added'
      });
    } catch (err) {
      console.error(err);
    }
  };
};
