import { Experiment, User } from '../../graphql/queries';

// collaborator role === owner
export const canImport = (experiment: Experiment, me: User) => !!experiment?.collaborators?.find((collaborator) => {
  return collaborator.user.id === me.id && (collaborator.role === 'owner');
});

// collaborator role === owner
export const canInviteCollaborators = (experiment: Experiment, me: User) => !!experiment?.collaborators?.find((collaborator) => {
  return collaborator.user.id === me.id && (collaborator.role === 'owner');
});

// collaborator role === owner | user
export const canSeeCollaborators = (experiment: Experiment, me: User) => !!experiment?.collaborators?.find((collaborator) => {
  return collaborator.user.id === me.id && (collaborator.role === 'owner' || collaborator.role === 'user');
});

// collaborator role === owner | user
export const canSeeResults = (experiment: Experiment, me: User) => !!experiment?.collaborators?.find((collaborator) => {
  return collaborator.user.id === me.id && (collaborator.role === 'owner' || collaborator.role === 'user');
});

// collaborator role === owner | user
export const canSeeTrials = (experiment: Experiment, me: User) => !!experiment?.collaborators?.find((collaborator) => {
  return collaborator.user.id === me.id && (collaborator.role === 'owner' || collaborator.role === 'user');
});