import React from 'react';
import { Switch, Route, RouteComponentProps, Router } from 'react-router-dom';
import { Layout } from 'antd';
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { createBrowserHistory } from 'history';
import { NavigationBar } from './navigation/NavigationBar';
import { AuthPage } from './auth/AuthPage';
// import { DashboardPage } from './modules/dashboard/DashboardPage';
import { ExperimentsPage } from './modules/experiments/ExperimentsPage';
import { ExperimentPage } from './modules/experiments/ExperimentPage';
import { ShufflePage } from './modules/evaluate/ShufflePage';
import { EvaluatePage } from './modules/evaluate/EvaluatePage';
import { UsersPage } from './modules/users/UsersPage';

const history = createBrowserHistory();

export type RouteWithIdProps = RouteComponentProps<{
  id: string;
}>;

const IS_AUTHENTICATED = gql`
  query {
    isAuthenticated @client
  }
`;

export const Routes = () => {
  const { data } = useQuery(IS_AUTHENTICATED);

  if (data?.isAuthenticated) {
    return (
      <Router history={history}>
        <Layout className="background">
          <NavigationBar />
          <Layout.Content>
            <Switch>
              {/*<Route exact path="/" component={DashboardPage} /> */}
              <Route exact path="/" component={ExperimentsPage} />
              <Route exact path="/users" component={UsersPage} />
              <Route exact path="/experiments" component={ExperimentsPage} />
              <Route exact path="/experiment/:id" component={ExperimentPage} />
              <Route exact path="/evaluate/:experimentId" component={ShufflePage} />
              <Route exact path="/evaluate/:experimentId/:trialId" component={EvaluatePage} />
            </Switch>
          </Layout.Content>
        </Layout>
      </Router>
    )
  } else {
    return <AuthPage />;
  }
}
