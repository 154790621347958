import { Experiment } from '../../graphql/queries';

export const formatTime = (seconds: number) => {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = Math.floor(seconds) % 60;
  const paddedSeconds = remainingSeconds < 10 ? `0${remainingSeconds}` : `${remainingSeconds}`;
  return `${minutes}:${paddedSeconds}`;
};

export const getDefaultActiveActivityName = (experiment: Experiment): string | null => {
  return experiment.config.activities.reduce((prev: string | null, curr) => {
    return curr.default ? curr.name : prev;
  }, null);
};