import React, { useState } from 'react';
import { Card, Table, Button, Tag } from 'antd';
import { RouteComponentProps } from 'react-router-dom';
import { PlusCircleOutlined } from '@ant-design/icons';
import { AddExperimentModal } from './modals/AddExperimentModal';
import { PageContent, Date, ErrorPage, DebouncedSearch } from '../../components';
import { Experiment, GET_EXPERIMENTS } from '../../graphql/queries';
import { useQuery } from '@apollo/react-hooks';
import style from './ExperimentsPage.module.css';

const PAGE_SIZE = 20;

const tableColumns = [
  {
    title: 'Name',
    key: 'name',
    render: (text: any, experiment: Experiment) => `${experiment.name}`
  },
  {
    title: 'Type',
    key: 'type',
    render: (text: any, experiment: Experiment) => <Tag>{experiment.type}</Tag>
  },
  {
    title: 'Created',
    key: 'createdAt',
    render: (text: any, experiment: Experiment) => <Date relative={true} date={`${experiment.created_at}`} />
  },
  {
    title: 'Trials',
    key: 'trials',
    render: (text: any, experiment: Experiment) => experiment.trials_aggregate?.aggregate.count
  }
];

export const ExperimentsPage = (props: RouteComponentProps) => {
  const [search, setSearch] = useState('');
  const { loading, error, data, fetchMore } = useQuery(GET_EXPERIMENTS, {
    variables: {
      limit: PAGE_SIZE,
      offset: 0,
      order_by: {
        created_at: 'desc'
      },
      where: search ? {
        name: {
          _ilike: `%${search}%`
        }
      } : {}
    }
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [modalVisible, setModalVisible] = useState(false);

  if (error) {
    console.error(error);
    return <ErrorPage />;
  }

  const handlePaginationChange = (page: number, pageSize?: number) => {
    const limit = pageSize || PAGE_SIZE;
    setCurrentPage(page);
    fetchMore({
      variables: {
        offset: (page - 1) * limit
      },
      updateQuery: (_, { fetchMoreResult }) => fetchMoreResult
    })
  };

  return (
    <>
      <PageContent>
        <Card>
          <div className={style.searchContainer}>
            <DebouncedSearch
              placeholder="Search"
              className={style.search}
              onSearch={(val: string) => {
                setSearch(val)
                setCurrentPage(1);
              }}
            />

            <Button
              type="primary"
              icon={<PlusCircleOutlined />}
              onClick={() => setModalVisible(true)}
              style={{marginLeft: '10px'}}
            >
              Add experiment
            </Button>
          </div>
          <Table
            className={style.experimentsTable}
            columns={tableColumns}
            onRow={(experiment: Experiment) => ({
              onClick: () => props.history.push(`/experiment/${experiment.id}`)
            })}
            pagination={{
              pageSize: PAGE_SIZE,
              onChange: handlePaginationChange,
              current: currentPage,
              total: data?.experiments_aggregate?.aggregate.count
            }}
            loading={loading}
            dataSource={data?.experiments}
          />
        </Card>
      </PageContent>
      <AddExperimentModal
        visible={modalVisible}
        onCloseModal={() => setModalVisible(false)}
      />
    </>
  )
};
