import React from 'react';
import { Typography } from 'antd';
import { SpinnerPage } from '../../../components/SpinnerPage';
import { Experiment } from '../../../graphql/queries';

const { Text } = Typography;

interface MeasurementActivitiesProps {
  experiment: Experiment;
}

export const MeasurementActivities = ({ experiment }: MeasurementActivitiesProps) => {
  if (!experiment?.config) {
    return <SpinnerPage />;
  }

  const { activities } = experiment.config;

  return (
    <ul>
      {activities.map(activity => (
        <li>
          {activity.trackCount ? 'Count: ' : 'Time: '}
          <Text code>{activity.name}</Text>
          {activity.default ? ' (default)' : ''}
        </li>
      ))}
    </ul>
  );
};
