import React from 'react';
import { Form, Input, Button, Modal, message } from 'antd';
import { FormProps } from 'antd/lib/form';
import { useMutation } from '@apollo/react-hooks';
import { Experiment, IMPORT_VIMEO_TRIALS } from '../../../graphql/queries';

interface ImportVideosModalProps extends FormProps {
  visible: boolean;
  experiment: Experiment;
  onCloseModal: () => void;
}

export const ImportVideosModal = (props: ImportVideosModalProps) => {
  const [form] = Form.useForm();
  const { visible, onCloseModal, experiment } = props;
  const [importVimeoTrials, { loading }] = useMutation(IMPORT_VIMEO_TRIALS);

  const getAlbumId = (uri: string): string => {
    const match = uri.match(/showcase\/([\d]+)/);
    if (match) {
      return match[1];
    }
    return '';
  };

  const onSubmit = async({ albumUrl }: { albumUrl: string}) => {
    try {
      await importVimeoTrials({
        variables: {
          experiment_id: experiment.id,
          vimeo_album_id: getAlbumId(albumUrl)
        },
        refetchQueries: ['GET_TRIALS']
      });
      onCloseModal();
      form.resetFields();
    } catch (err) {
      console.error(err);
      message.error('An unknown error occurred, please try again later');
    }
  };

  return (
    <Modal
      title="Import videos from Vimeo"
      visible={visible}
      onCancel={onCloseModal}
      footer={[
        <Button key="back" onClick={onCloseModal} disabled={loading}>
          Cancel
        </Button>,
        <Button key="submit" type="primary" loading={loading} onClick={() => form.submit()} disabled={loading}>
          Import
        </Button>
      ]}
    >
      <Form
        layout="vertical"
        onFinish={onSubmit as any}
        form={form}
      >
        <Form.Item
          name="albumUrl"
          label="Vimeo Showcase URL"
          rules={[{
              pattern: /https?:\/\/vimeo\.com\/showcase\/[\d]+/,
              message: 'Please input a valid Vimeo Showcase URL'
            }, {
              required: true,
              message: 'Please input the Vimeo Showcase URL!'
            }]}
          >
          <Input disabled={loading} autoFocus placeholder="https://vimeo.com/showcase/3867498" />
        </Form.Item>
      </Form>
    </Modal>
  );
};
