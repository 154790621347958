import React from 'react';
import { CaretUpOutlined, CaretDownOutlined, CaretLeftOutlined, CaretRightOutlined } from '@ant-design/icons';

import style from './Sidebar.module.css';

type KeyProps = {
  keyboardKey: string;
};

export const KeyboardKey = (props: KeyProps) => {
  const { keyboardKey } = props;
  let icon;

  if (keyboardKey === 'up' || keyboardKey === 'bottom') { // there is an error in BARNES, fix keyboard key in db before removing this
    icon = <CaretUpOutlined />;
  } else if (keyboardKey === 'down') {
    icon = <CaretDownOutlined />;
  } else if (keyboardKey === 'left') {
    icon = <CaretLeftOutlined />;
  } else if (keyboardKey === 'right') {
    icon = <CaretRightOutlined />;
  } else {
    icon = <>{keyboardKey}</>;
  }

  return <div className={style.key}>{icon}</div>
};
