import React from 'react';
import cx from 'classnames';
import { Layout, Avatar, Menu, Dropdown } from 'antd';
import { LogoutOutlined, UserOutlined } from '@ant-design/icons';
import { Link, useRouteMatch } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/react-hooks';
import md5 from 'md5'
import { ME_QUERY, LOGOUT_QUERY } from '../graphql/queries';
import logo from '../logo.png';
import style from './NavigationBar.module.css';

const { Header } = Layout;

export const NavigationBar = () => {
  // const isDashboardActive = useRouteMatch('/')?.isExact;
  const isExperimentsActive = useRouteMatch('/experiments');
  const { data } = useQuery(ME_QUERY);
  const [logout] = useMutation(LOGOUT_QUERY);

  const user = data?.me?.[0] || {};
  const avatar = `https://www.gravatar.com/avatar/${md5(user.email || '')}?s=100&d=mp`

  const userMenu = (
    <Menu>
      <Menu.Item disabled={true}>
        {user.email && `${user.first_name} ${user.last_name} <${user.email}>`}
      </Menu.Item>
      <Menu.Item onClick={() => logout()}>
        <LogoutOutlined />
        Logout
      </Menu.Item>
    </Menu>
  );

  return (
    <Header className={style.header}>
      <div className={style.logo} onClick={() => {}}>
        <Link to="/"><img src={logo} alt="" /></Link>
      </div>

      <ul className={style.menu}>
        <li className={cx(isExperimentsActive && style.active)}>
          <Link to="/experiments"><span>Experiments</span></Link>
        </li>
      </ul>
      <div className={style.userSettings}>
        <Dropdown overlay={userMenu} placement="bottomRight">
          <Avatar size={36} icon={<UserOutlined />} src={avatar} />
        </Dropdown>
      </div>
    </Header>
  );
};
