import React from 'react';

import Spinner from './Spinner';
import PageContent from './PageContent';

import style from './SpinnerPage.module.css';

interface SpinnerPageProps {
  size?: number;
}

export const SpinnerPage = ({ size = 24 }: SpinnerPageProps) => (
  <PageContent className={style.container}>
    <Spinner />
  </PageContent>
);

export default SpinnerPage;