import React, { useEffect } from 'react';
import { Form, Button, Modal, Radio, Typography, notification } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons'
import { useMutation } from '@apollo/react-hooks';
import { Collaborator, UPDATE_COLLABORATOR, DELETE_COLLABORATOR } from '../../../graphql/queries';

const radioStyle = {
  display: 'block'
};

interface EditCollaboratorModalProps {
  visible: boolean;
  collaborator?: Collaborator;
  onCloseModal: () => void;
}

export const EditCollaboratorModal = (props: EditCollaboratorModalProps) => {
  const { visible, onCloseModal, collaborator } = props;
  const [form] = Form.useForm();
  useEffect(() => form.resetFields(), [collaborator, form]);
  const [updateCollaborator, { loading: updatingCollaborator }] = useMutation(UPDATE_COLLABORATOR, {
    refetchQueries: ['GET_EXPERIMENTS', 'GET_EXPERIMENT']
  });
  const [deleteCollaborator, { loading: deletingCollaborator }] = useMutation(DELETE_COLLABORATOR, {
    refetchQueries: ['GET_EXPERIMENTS', 'GET_EXPERIMENT']
  });

  return (
    <Modal
      title={`Update collaborator ${collaborator?.user.email}`}
      visible={visible}
      onCancel={onCloseModal}
      footer={[
        <Button
          key="delete"
          onClick={onDeleteCollaborator}
          type="danger"
          style={{float: 'left'}}
          disabled={deletingCollaborator}
          loading={deletingCollaborator}
        >
          Delete
        </Button>,
        <Button key="back" onClick={onCloseModal}>
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={() => form.submit()}
          disabled={updatingCollaborator || deletingCollaborator}
          loading={updatingCollaborator}
        >
          Save
        </Button>
      ]}
    >
      <Form
        layout="vertical"
        onFinish={onFormSubmit as any}
        form={form}
        initialValues={{role: collaborator?.role}}
      >
        <Form.Item label="Role" name="role" rules={[{ required: true }]}>
          <Radio.Group>
            <Radio value="owner" style={radioStyle}>
              <Typography.Text strong>Owner</Typography.Text>
              <Typography.Paragraph style={{ paddingLeft: '24px' }}>Has full administrative access to this experiment.</Typography.Paragraph>
            </Radio>
            <Radio value="user" style={radioStyle}>
              <Typography.Text strong>User</Typography.Text>
              <Typography.Paragraph style={{ paddingLeft: '24px' }}>Can't edit the experiment or add videos, but can see the results</Typography.Paragraph>
            </Radio>
            <Radio value="evaluator" style={radioStyle}>
              <Typography.Text strong>Evaluator</Typography.Text>
              <Typography.Paragraph style={{ paddingLeft: '24px' }}>Can only evaluate trials, can't see the results</Typography.Paragraph>
            </Radio>
          </Radio.Group>
        </Form.Item>
      </Form>
    </Modal>
  );

  async function onFormSubmit(values: { role: string }) {
    try {
      await updateCollaborator({
        variables: {
          id: collaborator?.id,
          role: values.role
        }
      });
      props.onCloseModal();
      notification.success({
        message: 'Collaborator updated'
      });
    } catch (err) {
      notification.error({
        message: 'Failed to update collaborator',
        description: 'Please try again later'
      });
      console.error(err);
    }
  };

  function onDeleteCollaborator() {
    Modal.confirm({
      title: 'Are you sure you want to delete this collaborator?',
      content: 'All results created by this collaborator will be removed',
      icon: <ExclamationCircleOutlined />,
      okText: 'Delete',
      onOk: async() => {
        try {
          await deleteCollaborator({
            variables: {
              id: collaborator?.id
            }
          });
          notification.success({
            message: 'Collaborator removed'
          });
          props.onCloseModal();
        } catch (err) {
          notification.error({
            message: 'Failed to remove collaborator',
            description: 'Please try again later'
          });
          console.error(err);
        }
      }
    });
  }
};
