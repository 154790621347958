import React, { useState } from 'react';
import {
  Card,
  Row,
  Col,
  Descriptions,
  Button,
  Typography,
  Tag,
  Tooltip,
  Divider,
  List,
  Avatar,
  Progress
} from 'antd';
import { UserAddOutlined } from '@ant-design/icons';
import { useQuery } from '@apollo/react-hooks';
import md5 from 'md5';
import { SpinnerPage, PageContent, Date, ErrorPage } from '../../components';
import { RouteWithIdProps } from '../../Routes';
import { GET_EXPERIMENT, Experiment, Trial, Collaborator, User } from '../../graphql/queries';
import { InviteCollaboratorModal } from './modals/InviteCollaboratorModal';
import { EditCollaboratorModal } from './modals/EditCollaboratorModal';
import { ExportToCSVButton } from './components/ExportToCSVButton';
import { TrialModal } from './modals/TrialModal';
import { getType } from './experimentTypes';
import { TrialsList } from './TrialsList';
import { EvaluationStatus } from './components/EvaluationStatus';
import { MeasurementTimes } from './components/MeasurementTimes';
import { MeasurementActivities } from './components/MeasurementActivities';
import { StopConditions } from './components/StopConditions';
import { canInviteCollaborators, canSeeCollaborators, canSeeResults, canSeeTrials } from './permissions';

import style from './ExperimentPage.module.css';

export function ExperimentPage(props: RouteWithIdProps) {
  const experimentId = props.match.params.id;
  const { loading, error, data } = useQuery(GET_EXPERIMENT, {
    variables: {
      id: experimentId
    }
  });
  const experiment: Experiment = data?.experiments_by_pk;
  const [me]: User[] = data?.me || [];

  const [selectedTrial, setSelectedTrial] = useState({} as Trial);
  const [trialModalVisible, setTrialModalVisible] = useState(false);
  const [editCollaboratorModal, setEditCollaboratorModal] = useState<undefined | Collaborator>(undefined);
  const [inviteCollaboratorModalVisible, setInviteCollaboratorModalVisible] = useState(false);

  if (error) {
    return <ErrorPage message={error.message} />;
  }

  if (loading || !experiment) {
    return <SpinnerPage />;
  }

  return (
    <>
      <PageContent>
        <Row gutter={24}>
          <Col span={8}>
            <Card className={style.summary}>
              <Typography.Title level={2}>{experiment.name}</Typography.Title>

              <Descriptions column={1}>
                <Descriptions.Item label="Type">
                  <Tooltip title={getType(experiment.type).name}>
                    <Tag>{getType(experiment.type).short}</Tag>
                  </Tooltip>
                </Descriptions.Item>
                <Descriptions.Item label="Created"><Date relative={true} date={`${experiment.created_at}`} /></Descriptions.Item>
              </Descriptions>

              <Divider />
              <Typography.Title level={4}>Activities to measure:</Typography.Title>
              <MeasurementActivities experiment={experiment} />

              <Divider />
              <Typography.Title level={4}>Measurement timeline:</Typography.Title>
              <MeasurementTimes experiment={experiment} />

              <Divider />
              <Typography.Title level={4}>Stop condition:</Typography.Title>
              <StopConditions experiment={experiment} />

              {canSeeResults(experiment, me) && (
                <>
                  <Divider />
                  <Typography.Title level={4}>Results:</Typography.Title>
                  <div className={style.actionBar}>
                    <ExportToCSVButton experimentId={experimentId} />
                  </div>
                </>
              )}

              {canSeeCollaborators(experiment, me) && (
                <>
                  <Divider />
                  <Typography.Title level={4}>Collaborators:</Typography.Title>
                  <List
                    itemLayout="horizontal"
                    dataSource={experiment.collaborators}
                    renderItem={(collaborator) => (
                      <List.Item onClick={() => {setEditCollaboratorModal(collaborator)}} className={style.collaboratorItem}>
                        <List.Item.Meta
                          avatar={<Avatar src={getUserPhoto(collaborator.user.email)} size={48} />}
                          title={`${collaborator.user.email}`}
                          description={(
                            <Tooltip title="Evaluation progress (not implemented yet)">
                              <Progress
                                strokeColor="#ccc"
                                style={{ width: 150 }}
                                percent={30}
                                size="small"
                              />
                            </Tooltip>
                          )}
                        />
                        <Tag>{collaborator.role}</Tag>
                      </List.Item>
                    )}
                  />

                  {canInviteCollaborators(experiment, me) && (
                    <div className={style.inviteCollaboratorContainer}>
                      <Button
                        type="default"
                        icon={<UserAddOutlined />}
                        onClick={() => setInviteCollaboratorModalVisible(true)}
                      >
                        Invite collaborator
                      </Button>
                    </div>
                  )}
                </>
              )}
            </Card>
          </Col>
          <Col span={16}>
            <EvaluationStatus experiment={experiment} />
            {canSeeTrials(experiment, me) && (
              <TrialsList
                experiment={experiment}
                me={me}
                onTrialClick={(trial: Trial) => {
                  setSelectedTrial(trial);
                  setTrialModalVisible(true);
                }}
              />
            )}
          </Col>
        </Row>
      </PageContent>
      <TrialModal
        trial={selectedTrial}
        visible={trialModalVisible}
        onCloseModal={() => setTrialModalVisible(false)}
      />
      <InviteCollaboratorModal
        visible={inviteCollaboratorModalVisible}
        experiment={experiment}
        onCloseModal={() => setInviteCollaboratorModalVisible(false)}
      />
      <EditCollaboratorModal
        collaborator={editCollaboratorModal}
        onCloseModal={() => setEditCollaboratorModal(undefined)}
        visible={!!editCollaboratorModal}
      />
    </>
  );
}

function getUserPhoto(email: string) {
  return `https://www.gravatar.com/avatar/${md5(email || '')}?s=100&d=mp`
}