import React, { useState } from 'react';
import { Progress, Tooltip } from 'antd';
import { PlayCircleOutlined, SaveOutlined, PauseCircleOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/react-hooks';
import { Button, notification } from 'antd';
import { useHistory } from 'react-router-dom';
import ReactPlayer from 'react-player';
import { GlobalHotKeys } from 'react-hotkeys';

import {
  INSERT_RESULT,
  InsertResultResponse,
  Experiment,
  Trial,
  Activity,
  ActivityWithValue,
  MeasurementTime,
  GET_MY_PROGRESS,
  GET_TRIALS_TO_SHUFFLE
} from '../../graphql/queries';
import { Sidebar } from './Sidebar';
import { FocusHackButton } from './FocusHackButton';
import { formatTime } from './utils';
import style from './EvaluatePage.module.css';

const PROGRESS_INTERVAL = 100;

type EvaluatorProps = {
  experiment: Experiment;
  trial: Trial;
};

type Event = {
  type: string;
  activityName?: string;
  at: number;
};

const getKeyMap = (activities: Activity[]) => {
  const keyMap: any = {};
  activities.forEach(activity => {
    keyMap[activity.name] = activity.hotkey === 'bottom' ? 'up' : activity.hotkey;
  });
  keyMap['Play/Pause'] = 'space';
  return keyMap;
};

export function Evaluator(props: EvaluatorProps) {
  const { experiment, trial } = props;
  const history = useHistory();
  const { activities, stopConditions } = experiment.config;
  const [evaluationStatus, setEvaluationStatus] = useState({ progress: 0 /* in seconds */, playing: false, ended: false });
  const [events, setEvents] = useState<Event[]>([{ type: 'init', at: 0 }]);
  const [activitiesWithCount, setActivitiesWithCount] = useState(initActivitiesWithCount(activities));
  const [player, setPlayer] = useState<ReactPlayer | null>(null);
  const [duration, setDuration] = useState(0);
  const [saveResults, { loading: savingResults }] = useMutation<InsertResultResponse>(INSERT_RESULT);

  function togglePlayback() {
    if (evaluationStatus.ended) return;
    const shouldSeek = evaluationStatus.progress === 0;
    const seekTo = experiment.config.measurementTimes[0].from + (trial.video_delay || 0);
    const status = {
      ...evaluationStatus,
      playing: !evaluationStatus.playing
    };
    if (shouldSeek) {
      status.progress = seekTo; // offset progress by the seek value
      player?.seekTo(seekTo, 'seconds');
      addEvent(getSelectedActivity(), seekTo);
    }
    setEvaluationStatus(status);
  }

  async function doSaveResults() {
    try {
      await saveResults({
        variables: {
          trialId: trial.id,
          events,
          counts: activitiesWithCount.map(activity => ({
            name: activity.name,
            duration: activity.duration,
            count: activity.count
          }))
        },
        refetchQueries: [{
          query: GET_MY_PROGRESS,
          variables: { experimentId: experiment.id }
        }, {
          query: GET_TRIALS_TO_SHUFFLE,
          variables: { experimentId: experiment.id }
        }]
      });
      notification.success({
        message: 'Results saved'
      });
      history.push(`/experiment/${experiment.id}`);
    } catch (err) {
      console.error(err);
      notification.error({
        message: 'Failed to save results',
        description: 'Please try again later'
      });
    }
  }

  const onSelectActivity = (activity: ActivityWithValue) => {
    const active = activitiesWithCount.find(a => a.isActive);
    if (activity.trackCount) {
      incrementActivityCount(activity);
    } else if (!active || active.name !== activity.name) {
      selectActivity(activity);
    }
    addEvent(activity, evaluationStatus.progress);
  };

  const getHandlers = (activities: ActivityWithValue[]) => {
    const handlers: any = {};
    activities.forEach(activity => {
      handlers[activity.name] = () => onSelectActivity(activity);
    });
    handlers['Play/Pause'] = () => togglePlayback();
    return handlers;
  };

  return (
    <GlobalHotKeys
      keyMap={getKeyMap(activities)}
      handlers={getHandlers(activitiesWithCount)}
      allowChanges={true}
    >
      <div
        className={style.evaluateContainer}
        style={{ display: 'flex', flex: 1 }}
      >
        <FocusHackButton />
        <div className={style.playerContainer}>
          <ReactPlayer
            className={style.videoPlayer}
            url={trial.url}
            playing={evaluationStatus.playing}
            controls={false}
            muted={true}
            width="100%"
            height="600px"
            ref={setPlayer}
            onDuration={setDuration}
            onProgress={({ playedSeconds: progress }) => {
              // let diff = progress - (trial.video_delay || 0) - getMeasurementTimeDelay(progress);
              // progress = progress - getMeasurementTimeDelay(progress);
              if (shouldStop()) {
                endEvaluation();
              } else {
                bumpActivityDuration(getSelectedActivity(), progress - evaluationStatus.progress);
                setEvaluationStatus({ ...evaluationStatus, progress });
              }
            }}
            onEnded={() => endEvaluation()}
            progressInterval={PROGRESS_INTERVAL}
          />

          <div className={style.progressContainer}>
            <Progress
              percent={(evaluationStatus.progress / duration) * 100}
              showInfo={false}
              strokeColor="#002B64"
              strokeLinecap="square"
            />
            <span className={style.timeFrom}>0:00</span>
            <span className={style.timeTo}>{formatTime(duration)}</span>
            <span
              className={style.timeCurrent}
              style={{ left: `${(evaluationStatus.progress / duration) * 100}%` }}
            >{formatTime(evaluationStatus.progress)}</span>
            <Tooltip placement="bottomRight" title="Video start time">
              <div className={style.videoStartTimeTick} />
            </Tooltip>
            <Tooltip placement="bottomLeft" title="Video duration">
              <div className={style.videoEndTimeTick} />
            </Tooltip>
            {trial.video_delay ? (
              <Tooltip placement="bottomRight" title="Beginning of the experiment">
                <div className={style.videoDelayTimeTick} style={{ left: `${(trial.video_delay / duration) * 100}%` }} />
              </Tooltip>
            ) : <></>}
            {experiment.config.measurementTimes[0].from ? (
              <Tooltip placement="bottomRight" title="Beginning of the measurement">
                <div className={style.videoDelayTimeTick} style={{ left: `${(((trial.video_delay || 0) + experiment.config.measurementTimes[0].from) / duration) * 100}%` }} />
              </Tooltip>
            ) : <></>}
          </div>
        </div>
        <div className={style.sidebar}>
          <Sidebar
            activitiesWithCount={activitiesWithCount}
            selectActivity={onSelectActivity}
          />
          <div style={{paddingBottom: '24px'}}>
            <Button
              style={{marginRight: '12px'}}
              onClick={togglePlayback}
              icon={evaluationStatus.playing ? <PauseCircleOutlined /> : <PlayCircleOutlined />}
              size="large"
            >Play/Pause<br />
            </Button>
            <Button
              type="primary"
              disabled={!evaluationStatus.ended}
              onClick={doSaveResults}
              loading={savingResults}
              icon={<SaveOutlined />}
              size="large"
            >Save evaluation</Button>
          </div>
        </div>
      </div>
    </GlobalHotKeys>
  );

  function addEvent(activity: ActivityWithValue, at: number) {
    if (isWithinMeasurementTime(at, experiment.config.measurementTimes, trial.video_delay)) {
      setEvents([
        ...events,
        {
          type: 'activity',
          activityName: activity.name,
          at,
        }
      ]);
    }
  }

  function isWithinMeasurementTime(progress: number, measurementTimes: MeasurementTime[], delay?: number) {
    for (const measurementTime of measurementTimes) {
      const from = measurementTime.from + (delay || 0) - 0.01;
      const to = measurementTime.to ? measurementTime.to + (delay || 0) : null;

      if (progress >= from && progress <= (to || Infinity)) {
        return true;
      }
    }
    return false;
  }

  function shouldStop() {
    for (const stop of stopConditions) {
      // when duration of activities needs to be checked
      if (stop.combinedActivitiesLength && stop.activities) {
        const combinedActivitiesLength = activitiesWithCount.reduce((prev: number, activity: ActivityWithValue) => {
          if (stop.activities.indexOf(activity.name) !== -1 && !activity.trackCount) {
            return prev + activity.duration;
          }
          return prev;
        }, 0);
        if (combinedActivitiesLength >= stop.combinedActivitiesLength) {
          return true;
        }
      }

      // when a total length of activities is important
      if (stop.totalLength) {
        const countedLength = activitiesWithCount.reduce((prev: number, activity: ActivityWithValue) => {
          if (activity.trackCount) { // don't count events that only track count. Their "duration" is but a time of the last event
            return prev;
          } else {
            return prev + activity.duration;
          }
        }, 0);
        if (countedLength >= stop.totalLength) {
          return true;
        }
      }

      // on total count
      if (stop.totalCount && stop.activities) {
        const totalCount = activitiesWithCount.reduce((prev: number, activity: ActivityWithValue) => {
          if (stop.activities.indexOf(activity.name) !== -1) {
            return prev + activity.count;
          }
          return prev;
        }, 0);
        if (totalCount >= stop.totalCount) {
          return true;
        }
      }

      // when video has ended before condidtions were met
      // handled by onEnded callback in ReactPlayer
    }
    return false;
  }

  function endEvaluation() {
    setEvaluationStatus({ ...evaluationStatus, playing: false, ended: true });
  }

  function bumpActivityDuration(activity: ActivityWithValue, duration: number) {
    setActivitiesWithCount(activitiesWithCount.map(act => ({
      ...act,
      duration: act.duration + (activity.name === act.name ? duration : 0)
    })));
  }

  function incrementActivityCount(activity: ActivityWithValue) {
    setActivitiesWithCount(activitiesWithCount.map(act => ({
      ...act,
      count: act.count + (activity.name === act.name ? 1 : 0),
      duration: (activity.name === act.name ? (evaluationStatus.progress - (trial.video_delay || 0)) : act.duration)
    })));
  }

  function selectActivity(activity: ActivityWithValue) {
    setActivitiesWithCount(activitiesWithCount.map(act => ({
      ...act,
      count: act.count + (activity.name === act.name ? 1 : 0),
      isActive: activity.name === act.name
    })));
  }

  function getSelectedActivity() {
    for (const activity of activitiesWithCount) {
      if (activity.isActive) {
        return activity;
      }
    }
    return activitiesWithCount[0];
  }
};

function initActivitiesWithCount(activities: Activity[]): ActivityWithValue[] {
  return activities.map(activity => ({
    ...activity,
    count: 0,
    duration: 0,
    isActive: !!activity.default
  }));
}

