import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';
import { SpinnerPage, ErrorPage } from '../../components';
import { GET_EXPERIMENT_AND_TRIAL, GetExperimentAndTrialResponse } from '../../graphql/queries';
import { Evaluator } from './Evaluator';

type EvaluatePageProps = RouteComponentProps<{
  experimentId: string;
  trialId: string;
}>;

export function EvaluatePage(props: EvaluatePageProps) {
  const { experimentId, trialId } = props.match.params;

  const { loading, error, data } = useQuery<GetExperimentAndTrialResponse>(GET_EXPERIMENT_AND_TRIAL, {
    variables: {
      experimentId, trialId
    }
  });

  if (error) {
    return <ErrorPage message={error.message} />;
  }

  if (loading || !data) {
    return <SpinnerPage />;
  }

  const { trials_by_pk: trial, experiments_by_pk: experiment } = data;

  return <Evaluator experiment={experiment} trial={trial} />;
};
