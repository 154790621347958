import React from 'react';
import style from './ErrorPage.module.css';
import img from './ErrorPage.svg';

interface ErrorProps {
  message?: string;
  action?: JSX.Element;
}

const ErrorPage = (props: ErrorProps) => {
  const defaultMessage = 'An unknown error has occurred, please try again later';

  return (
    <div className={style.error}>
      <img src={img} alt="Error 500"/>
      <p className={style.message}>{props.message || defaultMessage}</p>
      {props.action}
    </div>
  );
}

export default ErrorPage;
