import React from 'react';
import { Modal } from 'antd';
import { FormProps } from 'antd/lib/form';
import { useMutation } from '@apollo/react-hooks';
import { DELETE_TRIALS } from '../../../graphql/queries';

interface DeleteTrialModalProps extends FormProps {
  visible: boolean;
  onCloseModal: () => void;
  setSelectedRowKeys: (keys: string[]) => void;
  selectedRowKeys: number[];
}

export const DeleteTrialModal = (props: DeleteTrialModalProps) => {
  const [deleteTrials] = useMutation(DELETE_TRIALS);
  const {
    visible,
    onCloseModal,
    selectedRowKeys,
    setSelectedRowKeys
  } = props;

  const onOk = async () => {
    deleteTrials({
      variables: {
        ids: selectedRowKeys
      },
      refetchQueries: ['GET_TRIALS']
    });
    onCloseModal();
    setSelectedRowKeys([]);
  };

  return (
    <Modal
      title={`Delete ${selectedRowKeys.length} trials?`}
      visible={visible}
      centered={true}
      okText="Delete"
      onOk={() => onOk()}
      onCancel={() => onCloseModal()}
    >
      <p>Do you want to permanently delete {selectedRowKeys.length} trials?</p>
    </Modal>
  );
};

