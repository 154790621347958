import React from 'react';
import { Redirect, RouteComponentProps } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';
import { SpinnerPage, ErrorPage } from '../../components';
import { GET_TRIALS_TO_SHUFFLE, GetTrialsToShuffleResponse } from '../../graphql/queries';

type ShufflePageProps = RouteComponentProps<{
  experimentId: string;
}>;

export function ShufflePage(props: ShufflePageProps) {
  const { experimentId } = props.match.params;
  const { loading, error, data } = useQuery<GetTrialsToShuffleResponse>(GET_TRIALS_TO_SHUFFLE, {
    variables: { experimentId }
  });

  if (error) {
    return <ErrorPage message={error.message} />;
  }

  if (loading || !data?.trials) {
    return <SpinnerPage />;
  }

  // get random trial and redirect to it
  const availableTrials = data.trials.filter(trial => !trial.my_results_aggregate.aggregate.count);

  if (availableTrials.length) {
    const trial = availableTrials[Math.floor(Math.random() * availableTrials.length)];
    return <Redirect to={`/evaluate/${experimentId}/${trial?.id}`} />;
  } else {
    return <>No trials to eval</>
  }
};
